import React from 'react';

import { t } from '@jotforminc/translation';

import './silverone_badge.scss';

// TODO: move it to growth asset manager
const OneDollarSilverBadge = () => {
  return (
    <div className="relative">
      <div className="silverone--badge">
        <div className="pulse flex flex-col justify-center items-center">
          {t('ONLY $1')}
          <p>{t('AT CHECKOUT')}</p>
        </div>
      </div>
    </div>
  );
};

export default OneDollarSilverBadge;
