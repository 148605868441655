import React, { useState, useEffect } from 'react';
import { node, string, bool } from 'prop-types';

import {
  Button, Modal, ModalBody
} from '@jotforminc/magnet';
import { IconXmark } from '@jotforminc/svg-icons';

import './lightbox.scss';

import { useAssetInfo } from '../../../hooks/useCampaignInfo';

const Lightbox = ({
  children,
  ariaLabel,
  open: openProp,
  className,
  showCloseIcon,
  onClose
}) => {
  const { type: campaignType } = useAssetInfo();

  const [open, setOpen] = useState(openProp);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    if (openProp !== open) {
      setOpen(openProp);
    }
  }, [openProp]);

  return (
    <Modal
      ariaLabel={ariaLabel}
      onClose={handleClose}
      size="large"
      open={open}
      id="gamLightbox"
      className={className}
      data-campaign-type={campaignType}
    >
      {showCloseIcon && (
        <div className="gamLightbox-close">
          <Button
            startIcon={IconXmark}
            colorStyle="secondary"
            rounded
            aria-label="Close Button"
            onClick={handleClose}
            size="small"
          />
        </div>
      )}
      <ModalBody>
        <div className="gamLightbox-body">
          {children}
        </div>
      </ModalBody>
    </Modal>
  );
};

Lightbox.propTypes = {
  children: node.isRequired,
  ariaLabel: string.isRequired,
  open: bool,
  className: string,
  showCloseIcon: bool,
  onClose: () => {}
};

Lightbox.defaultProps = {
  open: true,
  className: '',
  showCloseIcon: true,
  onClose: () => {}
};

export default Lightbox;
