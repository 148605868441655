import React, { useEffect } from 'react';
import cx from 'classnames';
import capitalize from 'lodash/capitalize';
import { t, translationRenderer } from '@jotforminc/translation';
import { renderEnterpriseCheckoutLikeSection, cleanEnterpriseCheckoutLikeSection } from '@jotforminc/ep-pricing-table';
import { setEPActions } from '@jotforminc/enterprise-promotions-utils';
import {
  IconAiColor,
  IconUsersMoreFilled,
  IconProductAppsMono,
  IconProductApprovalsColor
} from '@jotforminc/svg-icons';
import PlanHeader from './PlanHeader';
import FeatureBox from './FeatureBox';
import CheckIcon from '../assets/svg/check.svg';
import HipaaIcon from '../assets/svg/iconTableHipaa.svg';
import CustomizeIcon from '../assets/svg/customizeIcon.svg';

import {
  FEATURE_TITLES,
  PLAN_COLORS,
  PLANS,
  SOURCES,
  THEMES
} from '../constants';

import {
  CONTACT_SALES,
  CUSTOM_BRANDING,
  HIPAA_COMPLIANCE_AVAILABLE,
  GET_A_QUOTE,
  CUSTOM_PRICE,
  UNLIMITED_USAGE,
  MONTHLY_CONVERSATIONS,
  AGENTS,
  UNLIMITED,
  APPS,
  MONTHLY_WORKFLOW_RUNS
} from '../constants/texts';

import { usePricingContext } from '../context/pricingTableContext';

// eslint-disable-next-line complexity
const EnterprisePlan = () => {
  const { COLOR: CTA_BG_COLOR, TEXT: CTA_TEXT_COLOR } = PLAN_COLORS.DEFAULT[PLANS.ENTERPRISE];
  const {
    state: {
      user,
      theme,
      disableCallToActions,
      showHIPAABadgeOnHeader,
      source,
      campaignStatus,
      forceDiscount,
      pullUpHipaaBadge
    },
    onEnterpriseColumnClicked
  } = usePricingContext();
  const hasDiscount = campaignStatus || forceDiscount;

  const isAppsPricingPage = global.window?.location?.pathname?.includes('/products/apps/pricing/');
  const isAiAgentPricingPage = global.window?.location?.pathname?.includes('/ai-agents/pricing/');
  const isWorkflowPricingPage = source === SOURCES.WORKFLOWS_PRICING;

  useEffect(() => {
    setEPActions({ asset: 'enterpriseColumn', target: 'enterpriseColumn', action: 'seen' });
  }, []);

  const enterpriseTinyFeatures = [
    { title: 'Forms' },
    { title: 'Monthly Submissions' },
    { title: 'Signed Documents', isHiddenOnSubPages: false },
    { title: 'Submission Storage', isHiddenOnSubPages: true },
    { title: 'Payment Submissions', isHiddenOnSubPages: true },
    { title: 'API [1[and more...]]' }
  ];

  const enterpriseFeatures = [
    { title: 'Multiuser Platform', description: 'Multiple Teams and Users' },
    { title: 'Dedicated Support', description: 'Priority Real-Time Support' },
    { title: 'User Management', description: 'User Access Control' },
    { title: 'Single Sign-On', description: 'Microsoft, Google, Okta & more...' },
    { title: 'SLA', description: 'Service Level Agreements' },
    { title: 'Data Residency', description: 'Local Data Residency' },
    { title: 'Professional Services', description: 'Form & Workflow Building Services' }
  ];

  const highlightedFeatures = [];

  if (isAiAgentPricingPage) {
    highlightedFeatures.push({
      feature: 'aiAgent',
      info: AGENTS,
      Icon: IconUsersMoreFilled
    });
    highlightedFeatures.push({
      feature: 'aiConversation',
      info: MONTHLY_CONVERSATIONS,
      Icon: IconAiColor
    });
  }

  if (isAppsPricingPage) {
    highlightedFeatures.push({
      feature: 'apps',
      info: APPS,
      Icon: IconProductAppsMono
    });
  }

  if (isWorkflowPricingPage) {
    highlightedFeatures.push({
      feature: 'workflowRuns',
      info: MONTHLY_WORKFLOW_RUNS,
      Icon: IconProductApprovalsColor
    });
    // highlightedFeatures.push({
    //   feature: 'elementPerWorkflow',
    //   info: ELEMENTS_PER_WORKFLOW,
    //   Icon: IconFlowForkVerticalRadiusLine
    // });
  }

  const listClasses = cx('w-full max-w-72 radius-lg', {
    'sm:w-60 max-h-120 overflow-scroll px-2': theme === THEMES.LITE,
    'md:w-52 sm:w-60 xxl:w-68 p-1 mx-1': theme === THEMES.DEFAULT,
    'sm:w-48 px-1.5 pb-1.5': theme === THEMES.BILLING && hasDiscount,
    'sm:w-48 p-1.5': theme === THEMES.BILLING && !hasDiscount,
    'sm:w-64 p-1.5 mb-10': theme === THEMES.MINI,
    'mt-6': (theme !== THEMES.LITE) && !showHIPAABadgeOnHeader
  });

  const buttonClasses = cx('text-center', {
    'w-full h-full flex flex-col': theme !== THEMES.LITE,
    'cursor-pointer': !disableCallToActions
  });

  const planCTAClasses = cx('w-full', {
    'sticky bottom-0 z-1 bg-white': theme === THEMES.LITE,
    'p-3': theme !== THEMES.DEFAULT,
    'p-4': theme === THEMES.DEFAULT
  });
  const pricingPlanCTAClasses = cx('cursor-pointer flex justify-center items-center w-full font-bold transition hover:opacity-70 radius', {
    'h-12': theme === THEMES.DEFAULT,
    'h-10': theme !== THEMES.DEFAULT
  });

  const handlePlnClick = async (e, cb = f => f) => {
    e.stopPropagation();
    if (disableCallToActions) return;

    if (typeof cb === 'function') {
      cb();
    }

    onEnterpriseColumnClicked();

    window.history.pushState({ openModal: true }, '', '/pricing/enterprise');
    renderEnterpriseCheckoutLikeSection({ user });
  };

  useEffect(() => {
    const handlePopState = event => {
      if (event.state?.openModal) {
        renderEnterpriseCheckoutLikeSection({ user });
      } else {
        cleanEnterpriseCheckoutLikeSection();
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const handlePlanClick = async e => {
    handlePlnClick(e, () => {
      setEPActions({ asset: 'enterpriseColumn', target: 'enterpriseColumn', action: 'click' });
    });
  };
  const handleTopContactSalesClick = e => {
    handlePlnClick(e, () => {
      setEPActions({ asset: 'enterpriseColumn', target: 'contactSalesButttonTop', action: 'click' });
    });
  };
  const handleBottomContactSalesClick = e => {
    handlePlnClick(e, () => {
      setEPActions({ asset: 'enterpriseColumn', target: 'contactSalesButttonBottom', action: 'click' });
    });
  };

  const isNotSubThemes = (theme !== THEMES.BILLING && theme !== THEMES.LITE && theme !== THEMES.MINI);

  const enterpriseTitleWrapperClasses = cx('', {
    'pt-7 xxl:pt-6': isNotSubThemes && hasDiscount,
    'pt-6 xxl:pt-5': isNotSubThemes && !hasDiscount,
    'px-4 pb-4 ': isNotSubThemes,
    'px-3 pb-3 pt-9': theme === THEMES.BILLING && hasDiscount,
    'px-3 pb-3 pt-7': theme === THEMES.BILLING && !hasDiscount,
    'px-4 pb-4 pt-8': theme === THEMES.LITE,
    'p-6': theme === THEMES.MINI
  });

  const enterpriseTitleClasses = cx('text-center color-navy-600 font-bold tracking-xxxs', {
    'text-5xl xxl:text-6xl': theme === THEMES.DEFAULT && hasDiscount,
    'text-xl xxl:text-3xl': theme === THEMES.DEFAULT && !hasDiscount,
    'text-3xl': theme === THEMES.BILLING && hasDiscount,
    'text-2xl': (theme === THEMES.BILLING && !hasDiscount) || theme === THEMES.LITE,
    'text-xl font-black': theme === THEMES.MINI
  });

  const enterpriseButtonClasses = cx('duration-300 flex justify-center items-center w-full font-bold transition hover:opacity-70 radius', {
    'h-10 mt-6 xxl:mt-5': isNotSubThemes && !hasDiscount,
    'h-12 mt-7': isNotSubThemes && hasDiscount,
    'mt-10 h-10': theme === THEMES.BILLING && hasDiscount,
    'mt-5 h-10': theme === THEMES.BILLING && !hasDiscount,
    'h-10 mt-5': theme === THEMES.LITE,
    'h-10 mt-3': theme === THEMES.MINI
  });

  const enterpriseDetailListWrapperClasses = cx('px-2 radius-bl-md radius-br-md', {
    'xxl:px-4': isNotSubThemes,
    'pt-3': !pullUpHipaaBadge
  });

  const enterpriseDetailTitleClasses = cx('font-bold text-center text-lg line-height-xl color-navy-600', {
    'mt-0 mb-1.5': !isNotSubThemes,
    'mt-2 mb-2 xxl:mt-0 xxl:text-2xl xxl:line-height-2xl xxl:mb-1': isNotSubThemes,
    'pt-2 mt-2 mb-2 xxl:mt-0 xxl:text-2xl xxl:line-height-2xl xxl:mb-1': isNotSubThemes && pullUpHipaaBadge
  });

  const enterpriseDetailListClasses = cx('flex flex-col mb-3 max-w-48 ml-auto mr-auto pl-2', {
    'xxl:mb-1': isNotSubThemes
  });

  const enterpriseDetailListContentClasses = cx('flex items-center justify-start text-left text-xs line-height-xs color-navy-500 mb-1', {
    'xxl:text-sm': isNotSubThemes
  });

  const complianceWrapperClasses = cx('relative flex justify-center items-center text-sm py-3', {
    'py-4': isNotSubThemes
  });

  const complianceIconWrapperClasses = cx('flex items-center justify-center w-8 h-8 radius-full ml-auto mr-auto mb-2.5 border', {
    'w-11 h-11': isNotSubThemes
  });

  const complianceIconClasses = cx('transform w-5 h-auto', {
    'scale-75': !isNotSubThemes,
    'scale-1': isNotSubThemes
  });

  const complianceTextClasses = cx('font-medium text-xs color-navy-500 line-height-xs', {
    'xxl:text-sm xxl:line-height-sm': isNotSubThemes
  });

  // styles for FeatureBox
  const initializeFeatureBoxStyles = feature => {
    const teamsPricing = source === SOURCES.TEAMS_PRICING;
    const signPricing = source === SOURCES.SIGN_PRICING;
    const isAiAgentFeature = isAiAgentPricingPage && ['aiAgent', 'aiConversation'].includes(feature);
    const isAppsFeature = isAppsPricingPage && ['apps'].includes(feature);
    const isWorkflowFeature = isWorkflowPricingPage && [/* 'elementPerWorkflow', */'workflowRuns'].includes(feature);
    const highlightFeature = (feature === 'perTeam' && teamsPricing) || (feature === 'signedDocuments' && signPricing) || isAiAgentFeature || isAppsFeature || isWorkflowFeature;

    const letterSpacing = '-0.68px';

    const featureBoxClasses = cx({
      'py-6 xxl:py-4': theme === THEMES.DEFAULT && feature === FEATURE_TITLES.DATA_RESIDENCY,
      'py-5 xxl:py-4': theme === THEMES.BILLING && feature === FEATURE_TITLES.DATA_RESIDENCY,
      'py-4': theme === THEMES.DEFAULT && feature !== FEATURE_TITLES.DATA_RESIDENCY,
      'py-3': theme === THEMES.LITE || theme === THEMES.BILLING || theme === THEMES.MINI
    });

    const featureBoxTitleClasses = cx('text-lg color-navy-600', {
      'xxl:text-2xl line-height-2xl mb-2': theme !== THEMES.BILLING && theme !== THEMES.LITE && theme !== THEMES.MINI
    });

    let featureBoxInlineStyles = {
      borderBottom: '1px solid #b9bff5'
    };

    if (highlightFeature) {
      featureBoxInlineStyles = {
        ...featureBoxInlineStyles,
        backgroundColor: '#dcdffa',
        boxShadow: '0 6px 24px rgba(45,29,122,.5)',
        borderRadius: '2px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '-4px',
        marginLeft: '-8px',
        marginRight: '-8px',
        paddingBottom: '12px',
        marginBottom: '12px'
      };
    }

    const featureBoxContentTextBottomColor = '#3E437A';

    return {
      letterSpacing, featureBoxClasses, featureBoxTitleClasses, featureBoxInlineStyles, featureBoxContentTextBottomColor
    };
  };

  return (
    <li
      className={listClasses}
      data-testid='pricing-table-enterprise-column'
    >
      { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */ }
      <div
        className={buttonClasses}
        onClick={handlePlanClick}
        aria-label={PLANS.ENTERPRISE}
      >
        <PlanHeader plan={{ name: PLANS.ENTERPRISE, prettyName: capitalize(PLANS.ENTERPRISE) }} />

        <div
          className={enterpriseTitleWrapperClasses} style={{ backgroundColor: '#C8CEFF' }}
        >
          <span
            className={enterpriseTitleClasses}
          >
            {t(CUSTOM_PRICE)}
          </span>
          { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */ }
          <div
            onClick={e => handleTopContactSalesClick(e)}
            className={enterpriseButtonClasses}
            style={{
              backgroundColor: CTA_BG_COLOR,
              color: CTA_TEXT_COLOR
            }}
          >
            {t(GET_A_QUOTE)}
          </div>
        </div>

        <div className={enterpriseDetailListWrapperClasses} style={{ backgroundColor: '#DCE0FF' }}>
          {pullUpHipaaBadge && (
          <div className={`${complianceWrapperClasses} border-b border-navy-100`}>
            <span className="flex flex-col space-y-1">
              <strong className="font-bold block flex justify-center items-center mx-2 text-2xl mb-2">
                <HipaaIcon className="w-11 h-auto" />
              </strong>
              <span className={`${complianceTextClasses} w-28 xxl:w-auto`}>{t(HIPAA_COMPLIANCE_AVAILABLE)}</span>
            </span>
          </div>
          )}

          {highlightedFeatures.map(({ Icon, info, feature }) => {
            const title = Icon
              ? (
                <div className="flex justify-center items-center">
                  <i className="mr-2">
                    <Icon className="w-6 h-7 block" />
                  </i>
                  {t(UNLIMITED)}
                </div>
              )
              : <>{t(UNLIMITED)}</>;
            const descriptionTranslated = <>{t(info)}</>;

            return (
              <FeatureBox
                key={feature}
                title={title}
                description={descriptionTranslated}
                {...initializeFeatureBoxStyles(feature)}
              />
            );
          })}

          <h3 className={enterpriseDetailTitleClasses}>{t(UNLIMITED_USAGE)}</h3>
          <div className='border-b border-navy-100'>
            <ul className={enterpriseDetailListClasses}>
              {enterpriseTinyFeatures.map(({ title, isHiddenOnSubPages }) => {
                const titleTranslated = translationRenderer(title)({
                  renderer1: str => <strong key={str}>{str}</strong>
                });
                if (isHiddenOnSubPages && !isNotSubThemes) return false;
                return (
                  <li key={title} className={enterpriseDetailListContentClasses}>
                    <CheckIcon className="mr-1" />
                    <span>{titleTranslated}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          {enterpriseFeatures.map(({ title, description }) => {
            const titleTranslated = <>{t(title)}</>;
            const descriptionTranslated = <>{t(description)}</>;

            return (
              <FeatureBox
                key={title}
                title={titleTranslated}
                description={descriptionTranslated}
                {...initializeFeatureBoxStyles(title)}
              />
            );
          })}

          {!pullUpHipaaBadge && (
          <div className={`${complianceWrapperClasses} border-b border-navy-100`}>
            <span className="flex flex-col space-y-1">
              <strong className="font-bold block flex justify-center items-center mx-2 text-2xl mb-2">
                <HipaaIcon className="w-11 h-auto" />
              </strong>
              <span className={`${complianceTextClasses} w-28 xxl:w-auto`}>{t(HIPAA_COMPLIANCE_AVAILABLE)}</span>
            </span>
          </div>
          )}

          <div className={complianceWrapperClasses}>
            <span className="flex flex-col space-y-1">
              <div className={complianceIconWrapperClasses} style={{ background: '#5E42E8', borderColor: '#BBADFF' }}>
                <CustomizeIcon className={complianceIconClasses} />
              </div>
              <span className={complianceTextClasses}>{t(CUSTOM_BRANDING)}</span>
            </span>
          </div>

        </div>

        {!disableCallToActions && (
        <div className={planCTAClasses}>
          { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */ }
          <div
            className={pricingPlanCTAClasses}
            style={{ backgroundColor: CTA_BG_COLOR, color: CTA_TEXT_COLOR }}
            onClick={e => handleBottomContactSalesClick(e)}
          >
            {t(CONTACT_SALES)}
          </div>
        </div>
        )}
      </div>
    </li>
  );
};

export default EnterprisePlan;
