// TODO: commonize constants (subscription-wizard, myaccount, pricing-table)
export const CAMPAIGN_TYPES = {
  NONE: 'NONE',
  NEWUSER: 'NEWUSER',
  BLACKFRIDAY: 'BLACKFRIDAY',
  EOY: 'EOY',
  SUMMER: 'SUMMER',
  HIPAAGOLD: 'HIPAAGOLD',
  OVERQUOTAUSER: 'OVERQUOTAUSER',
  BACKTOPAID: 'BACKTOPAID',
  NONPROFIT: 'NONPROFIT',
  EDUCATION: 'EDUCATION',
  REGULARFIFTYDISCOUNT: 'REGULARFIFTYDISCOUNT',
  SAVEUPTOFORTYDISCOUNT: 'SAVEUPTOFORTYDISCOUNT',
  ONEDOLLARSILVER: 'ONEDOLLARSILVER',
  50: '50',
  30: '30',
  SUMMERCAMP: 'SUMMERCAMP'
};

export const PLANS = {
  'Sub User': 'Sub User',
  FREE: 'FREE',
  STORAGE: 'STORAGE',
  PREMIUM: 'PREMIUM',
  OLDPREMIUM: 'OLDPREMIUM',
  BRONZE: 'BRONZE',
  ECONOMY: 'ECONOMY',
  SILVER: 'SILVER',
  PROFESSIONAL: 'PROFESSIONAL',
  DIAMOND: 'DIAMOND',
  GOLD: 'GOLD',
  ENTERPRISE: 'ENTERPRISE',
  PLATINUM: 'PLATINUM',
  ADMIN: 'ADMIN'
};

export const ORDERED_PLAN_LIST = [
  PLANS.FREE,
  PLANS.STORAGE,
  PLANS.PREMIUM,
  PLANS.BRONZE,
  PLANS.ECONOMY,
  PLANS.SILVER,
  PLANS.PROFESSIONAL,
  PLANS.DIAMOND,
  PLANS.GOLD,
  PLANS.ENTERPRISE,
  PLANS.PLATINUM,
  PLANS.ADMIN
];

export const PERIODS = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
  BIYEARLY: 'biyearly'
};

export const PLAN_COLUMN_COLORS = {
  DEFAULT: {
    HEADER: '#394258',
    HEADER_TEXT: '#ffffff',
    HEADER_NOTE: '#B5BCC5',
    CONTENT: '#2b3245',
    CONTENT_TEXT: '#ffffff',
    CONTENT_TEXT_BOTTOM: '#C8CEED',
    CONTENT_BORDER: '#394258'
  },
  LIGHT: {
    HEADER: '#F3F3FE',
    HEADER_TEXT: '#0A1551',
    HEADER_NOTE: '#454E80',
    CTA: '#F3F3FE',
    CONTENT: '#E3E5F5',
    CONTENT_TEXT: '#343C6A',
    CONTENT_TEXT_BOTTOM: '#454E80',
    CONTENT_BORDER: '#C8CEED'
  }
};

export const PLAN_COLORS = {
  DEFAULT: {
    [PLANS.FREE]: {
      COLOR: '#01bd6f',
      TEXT: '#ffffff'
    },
    [PLANS.STORAGE]: {
      COLOR: '#00B9C5',
      TEXT: '#ffffff'
    },
    [PLANS.BRONZE]: {
      COLOR: '#ff6038',
      TEXT: '#ffffff'
    },
    [PLANS.SILVER]: {
      COLOR: '#2e69ff',
      TEXT: '#ffffff'
    },
    [PLANS.DIAMOND]: {
      COLOR: '#69ff',
      TEXT: '#2b3245'
    },
    [PLANS.GOLD]: {
      COLOR: '#ffb828',
      TEXT: '#2b3245'
    },
    [PLANS.ENTERPRISE]: {
      COLOR: '#6747ff',
      TEXT: '#ffffff'
    }
  },
  VARIANT_A: {
    [PLANS.FREE]: {
      COLOR: '#BFC3CE',
      TEXT: '#394258'
    },
    [PLANS.STORAGE]: {
      COLOR: '#00B9C5',
      TEXT: '#ffffff'
    },
    [PLANS.BRONZE]: {
      COLOR: '#4C536F',
      TEXT: '#ffffff'
    },
    [PLANS.SILVER]: {
      COLOR: '#E8000C',
      TEXT: '#ffffff'
    },
    [PLANS.DIAMOND]: {
      COLOR: '#69ff',
      TEXT: '#2b3245'
    },
    [PLANS.GOLD]: {
      COLOR: '#FFB828',
      TEXT: '#292D3C'
    },
    [PLANS.ENTERPRISE]: {
      COLOR: '#6747ff',
      TEXT: '#ffffff'
    }
  },
  VARIANT_B: {
    [PLANS.FREE]: {
      COLOR: '#BFC3CE',
      TEXT: '#394258'
    },
    [PLANS.STORAGE]: {
      COLOR: '#00B9C5',
      TEXT: '#ffffff'
    },
    [PLANS.BRONZE]: {
      COLOR: '#4C536F',
      TEXT: '#ffffff'
    },
    [PLANS.SILVER]: {
      COLOR: '#2E69FF',
      TEXT: '#ffffff'
    },
    [PLANS.DIAMOND]: {
      COLOR: '#69ff',
      TEXT: '#2b3245'
    },
    [PLANS.GOLD]: {
      COLOR: '#FFB828',
      TEXT: '#292D3C'
    },
    [PLANS.ENTERPRISE]: {
      COLOR: '#6747ff',
      TEXT: '#ffffff'
    }
  }
};

export const PLAN_DISCOUNT_PERCENTAGE_COLORS = {
  [PLANS.FREE]: {
    COLOR: '#01BD6F',
    TEXT: '#ffffff'
  },
  [PLANS.STORAGE]: {
    COLOR: '#01bdff',
    TEXT: '#ffffff'
  },
  [PLANS.BRONZE]: {
    COLOR: '#D8421D',
    TEXT: '#ffffff'
  },
  [PLANS.SILVER]: {
    COLOR: '#194CCE',
    TEXT: '#ffffff'
  },
  [PLANS.DIAMOND]: {
    COLOR: '#69ff',
    TEXT: '#2b3245'
  },
  [PLANS.GOLD]: {
    COLOR: '#E19701',
    TEXT: '#2B3245'
  },
  [PLANS.ENTERPRISE]: {
    COLOR: '#4D2DE3',
    TEXT: '#ffffff'
  }
};

export const SIGN_PLAN_COLORS = {
  [PLANS.FREE]: {
    COLOR: '#01bd6f',
    BACKGROUND: '#FFFFFF',
    HEADER_NOTE: '#454E80',
    CONTENT_TEXT: '#0A1551',
    CONTENT_TEXT_BOTTOM: '#6C73A8'
  },
  [PLANS.STORAGE]: {
    COLOR: '#00B9C5',
    BACKGROUND: '#FFFFFF',
    HEADER_NOTE: '#454E80',
    CONTENT_TEXT: '#0A1551',
    CONTENT_TEXT_BOTTOM: '#6C73A8'
  },
  [PLANS.BRONZE]: {
    COLOR: '#ff6038',
    BACKGROUND: '#FFFFFF',
    HEADER_NOTE: '#454E80',
    CONTENT_TEXT: '#0A1551',
    CONTENT_TEXT_BOTTOM: '#6C73A8'
  },
  BEST_PLAN: {
    COLOR: '#ffffff',
    BACKGROUND: '#2e69ff',
    HEADER_NOTE: '#C8CEED',
    CONTENT_TEXT: '#FFFFFF',
    CONTENT_TEXT_BOTTOM: '#C8CEED'
  },
  [PLANS.SILVER]: {
    COLOR: '#2e69ff',
    BACKGROUND: '#FFFFFF',
    HEADER_NOTE: '#454E80',
    CONTENT_TEXT: '#0A1551',
    CONTENT_TEXT_BOTTOM: '#6C73A8'
  },
  [PLANS.DIAMOND]: {
    COLOR: '#ffb828',
    BACKGROUND: '#FFFFFF',
    HEADER_NOTE: '#454E80',
    CONTENT_TEXT: '#0A1551',
    CONTENT_TEXT_BOTTOM: '#6C73A8'
  },
  [PLANS.GOLD]: {
    COLOR: '#ffb828',
    BACKGROUND: '#FFFFFF',
    HEADER_NOTE: '#454E80',
    CONTENT_TEXT: '#0A1551',
    CONTENT_TEXT_BOTTOM: '#6C73A8'
  },
  [PLANS.ENTERPRISE]: {
    COLOR: '#6747ff',
    BACKGROUND: '#FCFBFF',
    HEADER_NOTE: '#454E80',
    CONTENT_TEXT: '#0A1551',
    CONTENT_TEXT_BOTTOM: '#6C73A8'
  }
};

export const SOURCES = {
  BILLING: 'billing',
  PRICING: 'pricing',
  // TODO: remove HIPAA_PRICING after legacy pricing landing page is removed
  HIPAA_PRICING: 'hipaa-pricing',
  TEAMS_PRICING: 'teams-pricing',
  SIGN_PRICING: 'sign-pricing',
  APPS_PRICING: 'apps-pricing',
  WORKFLOWS_PRICING: 'workflows-pricing',
  NONPROFIT_PRICING: 'nonprofit-pricing',
  EDUCATION_PRICING: 'education-pricing',
  LIMIT_DIALOG: 'limit-dialog',
  LIGHTBOX: 'lightbox',
  BLACK_FRIDAY_PAGE: 'black-friday-page',
  BUY_ONE_GET_ONE: 'buy-one-get-one',
  G2_DISCOUNT_PAGE: 'g2-discount-page',
  SUMMER_CAMP: 'summer-camp',
  SURVEYS: 'surveys',
  TYPEFORM_ALTERNATIVE: 'typeform-alternative',
  SQUARE_APP_WEEK: 'square-app-week'
};

export const THEMES = {
  DEFAULT: 'default',
  LITE: 'lite',
  BILLING: 'billing',
  MINI: 'mini'
};

export const COLOR_THEMES = {
  DARK: 'dark',
  LIGHT: 'light'
};

export const PLAN_LAYOUTS = {
  COLUMN: 'COLUMN',
  CHECKOUT_CARD: 'CHECKOUT_CARD'
};

export const FEATURE_TITLES = {
  DATA_RESIDENCY: 'Data Residency'
};

export const PRICE_TYPES = {
  USER: 'USER',
  NONPROFIT: 'NONPROFIT',
  DISCOUNTED: 'DISCOUNTED'
};
