/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';

import { t } from '@jotforminc/translation';

import './v1.scss';

import Modal from '../../../../../ui/Lightbox/Lightbox';

const SilverOneModal = () => {
  const [startCloseAnimation, setStartCloseAnimation] = useState(false);
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setStartCloseAnimation(true);
  };

  useEffect(() => {
    if (startCloseAnimation) {
      setTimeout(() => {
        setOpen(false);
      }, 600);
    }
  }, [startCloseAnimation]);

  return (
    <>
      {!open && (
        <div className="silverone--lightbox-small">
          <div className="silverone--lightbox-small--image">
            <img
              className="money"
              src="https://cdn.jotfor.ms/assets/img/campaigns/2024/only-one/lightbox/coin.png"
              alt="Coin"
            />
          </div>
          <div className="silverone--lightbox-small--heading">
            <div className="badge">
              <div className="badge-inline">{t('LIMITED TIME OFFER')}</div>
            </div>
            <div className="top-title">{t('Silver Plan')}</div>
            <div className="title">{t('Only $1')}</div>
            <div className="bottom-title">
              {t('FOR A YEAR')}
              *
            </div>
          </div>
          <div className="silverone--lightbox-small--button">
            <a href="#" onclick="">Upgrade now</a>
          </div>
        </div>
      )}
      <Modal
        className={startCloseAnimation ? 'lightbox-animation' : ''}
        open={open}
        showCloseIcon={false}
        ariaLabel="SilverOne Lightbox"
        title="SilverOne Lightbox"
      >
        <div className="lightbox-content">
          <button
            type='button'
            onClick={handleClose}
          >
            CLOSE
          </button>
          <div className="lightbox--modal-content">
            <div className="modal-content-top">
              <div className="lightbox--wrapper">
                <div className="center">
                  <div className="badge">
                    <div className="badge-inline">{t('LIMITED TIME OFFER')}</div>
                  </div>
                  <div className="heading">
                    <div className="top-title">{t('Silver Plan')}</div>
                    <div className="title">{t('Only $1')}</div>
                    <div className="bottom-title">
                      {t('FOR A YEAR')}
                      *
                    </div>
                    <div className="subtitle">
                      {t('Get more than double your limits')}
                      {' '}
                      <br />
                      {' '}
                      {t('by upgrading to a Silver plan.')}
                    </div>
                  </div>
                  <div className="button">
                    <a href="#" className="button-1">
                      Upgrade now
                    </a>
                  </div>
                </div>
                <img
                  className="money"
                  src="https://cdn.jotfor.ms/assets/img/campaigns/2024/only-one/lightbox/coin.png"
                  alt="Coin"
                />
              </div>
            </div>
            <div className="modal-content-footer">
              <p className="lightbox-modal--text">
                {t(
                  '*Offer does not apply to Jotform Enterprise. The $1 offer is a one-time offer for Bronze users upgrading to a Silver plan. Plans will switch to Silver for the remainder of the existing Bronze billing cycle and be charged at the price of an annual Silver plan at the end of the billing cycle. Cannot be combined with other offers.'
                )}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SilverOneModal;
