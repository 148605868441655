import React from 'react';
import {
  bool, func, shape, string
} from 'prop-types';
import { LimitBanner } from '@jotforminc/ep-account-box';
import { Trackings } from '../../constants';

const EnterpriseInlineLimitAlert = ({
  user,
  isTestVariant,
  logAbTestAction,
  registerJotformAction,
  ...props
}) => {
  const handleEnterprisePromoSeen = () => {
    const { ENTERPRISE_ALERT_SEEN } = Trackings;
    registerJotformAction(ENTERPRISE_ALERT_SEEN);
    logAbTestAction({ action: 'seen', target: 'enterpriseInlineLimitAlert' });
  };

  const handleEnterprisePromoCsClick = () => {
    const { ENTERPRISE_ALERT_CS_CLICK } = Trackings;
    registerJotformAction(ENTERPRISE_ALERT_CS_CLICK);
    logAbTestAction({ action: 'click', target: 'contactSalesButton' });
  };

  const handleEnterprisePromoLmClick = () => {
    const { ENTERPRISE_ALERT_LM_CLICK } = Trackings;
    registerJotformAction(ENTERPRISE_ALERT_LM_CLICK);
    logAbTestAction({ action: 'click', target: 'learnMoreButton' });
  };

  return (
    <LimitBanner
      {...props}
      isTestVariant={isTestVariant}
      onSeen={handleEnterprisePromoSeen}
      onPrimaryClick={handleEnterprisePromoCsClick}
      onSecondaryClick={handleEnterprisePromoLmClick}
    />
  );
};

EnterpriseInlineLimitAlert.propTypes = {
  user: shape({
    account_type: shape({
      name: string,
      limits: shape({})
    }),
    avatarUrl: string,
    username: string,
    usage: shape({}),
    location: shape({}),
    campaign: shape({})
  }).isRequired,
  isTestVariant: bool,
  logAbTestAction: func,
  registerJotformAction: func.isRequired
};

EnterpriseInlineLimitAlert.defaultProps = {
  isTestVariant: false,
  logAbTestAction: f => f
};

export default EnterpriseInlineLimitAlert;
