import React from 'react';
import {
  bool, func, oneOfType, shape, string
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { handleCustomNavigation, isSalesforceEnv } from '@jotforminc/utils';
import { AdminConsolePromotionButton } from '@jotforminc/ep-account-box';
import { Texts, Trackings } from '../../constants';

const MenuItems = ({
  user,
  adminConsoleLink,
  appName,
  isEnterprise,
  showAdminConsole,
  showAdminConsoleWarning,
  logoutURLParameter,
  handleSwitchToOld,
  isAccountBoxOpened,
  toggleAccountBox,
  registerABTestAction,
  registerJotformAction,
  showAdminConsolePromotion,
  showSettings,
  settingsDontUseRootPath
}) => {
  const userAccountType = user?.account_type?.name;
  const showSwitchToOld = appName === 'tables' && handleSwitchToOld;
  const {
    ADMIN_CONSOLE_PROMO_CLICK, SETTINGS_CLICK, SWITCH_TO_OLD_CLICK, LOGOUT_CLICK, ADMIN_CONSOLE_URL_CLICK
  } = Trackings;

  const handleAdminConsolePromotionClick = () => {
    registerABTestAction({ action: 'click', target: 'accountBox-adminConsolePromotion' });
    registerJotformAction(ADMIN_CONSOLE_PROMO_CLICK);
  };

  const handleSettingsClick = () => {
    try {
      registerABTestAction({ action: 'click', target: 'accountBox-settings' });
      registerJotformAction(SETTINGS_CLICK);
    } finally {
      handleCustomNavigation('/myaccount/settings', '_blank', settingsDontUseRootPath);
    }
  };

  const handleSwitchToOldWithToggle = () => {
    try {
      registerJotformAction(SWITCH_TO_OLD_CLICK);
    } finally {
      if (isAccountBoxOpened) { toggleAccountBox(); }
      handleSwitchToOld();
    }
  };

  const handleLogoutClick = () => {
    try {
      registerJotformAction(LOGOUT_CLICK);
    } finally {
      handleCustomNavigation(`/logout.php${logoutURLParameter}`, '_self', true);
    }
  };

  return (
    <ul className="jNewHeader-accountLinkList" role='menu'>
      {/* enterpsie promotions: add & manage users */}
      {!isEnterprise && showAdminConsolePromotion && (
        <li className='jNewHeader-accountLinkListItem adminConsolePromotion' role="presentation">
          <AdminConsolePromotionButton
            user={user}
            onClick={handleAdminConsolePromotionClick}
          />
        </li>
      )}
      {/* settings */}
      {showSettings && (
        <li className="jNewHeader-accountLinkListItem" role="presentation">
          <button
            type="button"
            data-testid="settingsButton"
            className="locale jNewHeader-accountLinkListItemLink"
            onClick={handleSettingsClick}
            role="menuitem"
          >
            {t(Texts.SETTINGS)}
          </button>
        </li>
      )}
      {/* admin console */}
      {showAdminConsole && (
        <li className="jNewHeader-accountLinkListItem" role="presentation">
          <a
            href={adminConsoleLink}
            onClick={e => {
              e.preventDefault();
              registerJotformAction(ADMIN_CONSOLE_URL_CLICK);
              handleCustomNavigation(adminConsoleLink, '_self', true);
            }}
            data-testid="adminConsoleButton"
            className="locale jNewHeader-accountLinkListItemLink"
            role="menuitem"
          >
            <span className={showAdminConsoleWarning && `notice ${showAdminConsoleWarning}`}>{t(Texts.ADMIN_CONSOLE)}</span>
          </a>
        </li>
      )}
      {/* switch to old */}
      {showSwitchToOld && (
        <li className="jNewHeader-accountLinkListItem" role="presentation">
          <button
            type="button"
            data-testid="switchToOldButton"
            className="locale jNewHeader-accountLinkListItemLink"
            onClick={handleSwitchToOldWithToggle}
            role="menuitem"
          >
            {t(Texts.SWITCH_TO_OLD_VERSION)}
          </button>
        </li>
      )}
      {/* get the mobile app */}
      {isEnterprise && userAccountType !== 'FORM_USER' && !isSalesforceEnv() && (
        <li className="jNewHeader-accountLinkListItem" role="presentation">
          <a
            href='/mobile?campaign=get-the-app'
            data-testid="getTheMobileAppButton"
            className="locale jNewHeader-accountLinkListItemLink"
            role="menuitem"
            target="_blank"
          >
            {t(Texts.GET_THE_APP)}
          </a>
        </li>
      )}
      {/* logout */}
      {!isSalesforceEnv() && (
        <li className="jNewHeader-accountLinkListItem" role="presentation">
          <button
            type="button"
            data-testid="logoutButton"
            className="locale jNewHeader-accountLinkListItemLink jNewHeader-accountLinkLogout"
            onClick={handleLogoutClick}
            role="menuitem"
          >
            {t(Texts.LOGOUT)}
          </button>
        </li>
      )}
    </ul>
  );
};

MenuItems.propTypes = {
  user: shape({}).isRequired,
  isEnterprise: bool.isRequired,
  showAdminConsole: bool.isRequired,
  showAdminConsoleWarning: string,
  registerABTestAction: func.isRequired,
  handleSwitchToOld: oneOfType([
    func,
    bool
  ]),
  adminConsoleLink: string,
  appName: string,
  logoutURLParameter: string,
  isAccountBoxOpened: bool,
  toggleAccountBox: func,
  registerJotformAction: func.isRequired,
  showAdminConsolePromotion: bool,
  showSettings: bool,
  settingsDontUseRootPath: bool
};

MenuItems.defaultProps = {
  handleSwitchToOld: false,
  adminConsoleLink: '/admin',
  appName: '',
  logoutURLParameter: '',
  isAccountBoxOpened: false,
  toggleAccountBox: f => f,
  showAdminConsoleWarning: undefined,
  showAdminConsolePromotion: true,
  showSettings: true,
  settingsDontUseRootPath: false
};

export default MenuItems;
